import './Callbanner.css';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './CallbackModal.css'
import axios from 'axios';

function Callbanner() {
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [newName, setNewName] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleConfirm = async () => {
    handleCloseModal();
    const newNotificationMessage = "Thank you for contacting us. We will get back in touch as soon as possible.";
    const jsonData = {
        title: "Callback request",
        message: `Name: ${newName}\nPhone Number: ${newPhoneNumber}`
    };
    setModalType("notification");
    setModalMessage(newNotificationMessage);
    handleOpenModal();
    try {
      const response = await axios.post('https://ogfun6xoyi.execute-api.eu-west-1.amazonaws.com/default/emailSender', jsonData);

      if (response.status === 200) {
        console.log('Email sent successfully');
          // Handle success, e.g., show a success message to the user
      } else {
        console.error('Failed to send email. Unexpected status code:', response.status);

          // Handle unexpected response status code
      }
    } catch (error) {
        console.error('Failed to send email:', error);
        // Handle error, e.g., show an error message to the user
    }
  };

  const CallbackModal = ({ show, handleClose, modalMessage, modalType }) => {
    if (modalType === "confirmation") {
      return (
        <Modal className="modal" show={show} onHide={handleClose} dialogClassName="modal-lg">
          <Modal.Body className="modal-text">
            <div className="confirmation-message">{modalMessage}</div>
          </Modal.Body>
          <div className="confirmation-buttons">
            <button className="modal-close-button" onClick={handleConfirm}> Confirm </button>
            <button className="modal-close-button" onClick={handleClose}> Close </button>
          </div>
        </Modal>
      );
    } else if (modalType === "notification") {
      return (
        <Modal className="modal" show={show} onHide={handleClose} dialogClassName="modal-lg">
          <Modal.Body className="modal-text">
            <div className="confirmation-message">{modalMessage}</div>
          </Modal.Body>
          <div className="confirmation-buttons">
            <button className="modal-close-button" onClick={handleClose}> Close </button>
          </div>
        </Modal>
      );
    }

  };
  const handleSubmit = () => {
    if (!name || !phoneNumber ) {
      const newNotificationMessage = "Please fill out all the fields before submitting";
      setModalType("notification");
      setModalMessage(newNotificationMessage);
    }  else {
      setNewPhoneNumber(phoneNumber);
      setNewName(name);

      const newConfirmationMessage = `
        Please confirm your details are correct:

        Name: ${name}
        Phone Number: ${phoneNumber}
      `;
        setModalType("confirmation");
        setModalMessage(newConfirmationMessage);
        clearFields();
    }
      handleOpenModal();
  };
  const clearFields = () => {
  // Reset all state variables to empty strings
    setName('');
    setPhoneNumber('');
  };
  return (
    <div className="callbanner">
      <div className ="callbanner-content">
        <p className ="callbanner-text">Want us to call you?</p>
        <input
          type="text"
          className="callbanner-input-phone"
          placeholder = "Name"
          value={name}
          onChange={(e) => {
            const input = e.target.value.slice(0, 50); // Limit input to 100 characters
            setName(input);
          }} />
        <input
          type="tel"
          className="callbanner-input-phone"
          placeholder = "Phone Number"
          value={phoneNumber}
          onChange={(e) => {
            const input = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
            setPhoneNumber(input.slice(0, 10)); // Limit to 10 digits
          }} />
        <button className="callbanner-button" onClick={handleSubmit}>Callback</button>
      </div>
      <CallbackModal show={showModal} handleClose={handleCloseModal} modalMessage={modalMessage} modalType={modalType} />
    </div>
  );
}

export default Callbanner;
